import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-section-media',
  templateUrl: './section-media.component.html',
  styleUrls: ['./section-media.component.scss']
})
export class SectionMediaComponent implements OnInit {
  media = [
    {
      'title' : 'Kabarbumn.com',
      'logo' : '/assets/logo/mandiri-logo-white.svg',
      'subtitle' : 'Sabtu, 17 Desember 2024',
      'headline' : 'Wirausaha Muda Mandiri (WMM) 2024 Memasuki Tahap Akhir, Top 20 Finalis Siap Rebutkan Gelar Jawara',
      'image_url' : '/assets/image/home-bg-6.jpeg',
      'link' :'https://www.kabarbumn.com/rilis-bumn/115432213/wirausaha-muda-mandiri-wmm-2024-memasuki-tahap-akhir-top-20-finalis-siap-rebutkan-gelar-jawara',

    },

    {
      'title' : ' CNBC',
      'logo' : '/assets/logo/logo_cnbc_putih.png',
      'subtitle' : 'Jumat, 19 Januari 2024',
      'headline' : 'Yuk Meriahkan Wirausaha Muda Mandiri, Cek Rangkaian Acaranya',
      'image_url' : '/assets/image/DEV03137.jpg',
      'link' :'https://www.cnbcindonesia.com/entrepreneur/20240119101232-25-507191/yuk-meriahkan-wirausaha-muda-mandiri-cek-rangkaian-acaranya',

    },

    {
      'title' : 'Bisnis.com',
      'logo' : '/assets/logo/mandiri-logo-white.svg',
      'subtitle' : 'Jumat, 19 Januari 2024',
      'link' :'https://finansial.bisnis.com/read/20240119/55/1733678/wirausaha-muda-mandiri-2023-sebanyak-24-finalis-beradu-skill-di-surabaya',
      'headline' : 'Wirausaha Muda Mandiri 2023, Sebanyak 24 Finalis Beradu Skill di Surabaya',
      'image_url' : '/assets/image/DEV03026.jpg',
    },

    {
      'title' : ' Republika',
      'logo' : '/assets/logo/Logo_Republika.png',
      'subtitle' : 'Sabtu, 20 Januari 2023',
      'link' :'https://ekonomi.republika.co.id/berita/s74qd8463/cetak-pebisnis-baru-bank-mandiri-gelar-wirausaha-muda-mandiri-2023',

      'headline' : ' Bank Mandiri Rilis Nama Juara Wirausaha Muda Mandiri 2023',
      'image_url' : '/assets/image/republika.jpeg',
    },


   
    
   
  ]
  constructor() { }

  ngOnInit(): void {
  }

}
