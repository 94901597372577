import { Component, OnInit } from '@angular/core';
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-section-about',
  templateUrl: './section-about.component.html',
  styleUrls: ['./section-about.component.scss']
})
export class SectionAboutComponent implements OnInit {
  active_program : any
  faPlus=faPlus
  faMinus=faMinus
  showElement : any
  about_nav = [
    {
      'title' : 'Aktivitas',
      
    },
    {
      'title' : 'Kriteria Peserta'
    },
    {
      'title' : 'Kriteria Bisnis'
    },
    {
      'title' : 'Keuntungan Program'
    },
    {
      'title' : 'FAQ'
    }
  ]
  about_nav_mobile = [
   
    {
      'title' : 'Kriteria Peserta'
    },
    {
      'title' : 'Kriteria Bisnis'
    },
    {
      'title' : 'Keuntungan Program'
    },
    {
      'title' : 'FAQ'
    }
  ]


  participant = [
    // {
    //   'title' : 'Pengusaha yang yang merupakan warga negara Indonesia berumur 18-35 tahun ',
    // },
    {
      'title' : 'Omset per tahun minimal Rp 500 juta atau Bisnis sudah berjalan minimal 2 tahun untuk kategori Fashion dan Food & Beverages, Beauty & Wellness, dan 1 tahun untuk Tech & Tech-Enabled Startups'
    },
    {
      'title' : 'Usaha/inovasi tidak melanggar hukum'
    },
    {
      'title' : 'Peserta merupakan Pemilik/Co-Founder/CEO/C-Level dari bisnis yang didaftarkan - 1 orang per perusahaan'
    },
    {
      'title' : 'Memiliki rekening Bank Mandiri. Dapat berupa rekening pribadi maupun rekening bisnis. Apabila belum mempunyai silahkan melakukan proses pembuatan rekening dengan mudah ke tautan berikut :  <a style="color:white !important" target="_blank"  href="https://bankmandiri.co.id/livin/edukasi/cara-buka-rekening">Cara Buka Rekening</a> '
    },
    
  ]


  benefit = [
    {
      'title' : 'Networking dan berkenalan langsung secara lebih dekat dengan sesama pengusaha, mentor, dan investor.',
    },
    {
      'title' : 'Mentoring dan workshop intensif dari para pengusaha sukses, ternama, terdepan di industrinya.'
    },
    {
      'title' : 'Bertemu dengan lebih dari 20 calon investor melalui aktivitas speed dating.'
    },
    {
      'title' : 'Akses eksklusif ke pameran-pameran dalam jejaring Bank Mandiri.'
    },
    {
      'title' : 'Forum terpercaya/support group bersama sesama pengusaha melalui Alumni Hub Wirausaha Muda Mandiri.'
    },
    {
      'title' : 'Apresiasi dan penghargaan dari Bank Mandiri'
    },
    
  ]



  business = [
    {
      'title' : 'Beauty & Wellness',
      'subtitle' : 'Produk-produk yang berfungsi untuk perawatan diri dan menjaga kecantikan, serta membuat rutinitas perawatan diri harian menjadi menyenangkan dan efektif.',
      'items' : [
        {
          'title' : 'Skincare',
          'subtitle' : 'Cleanser, Mist, Lotion, Serum'
        },
        {
          'title' : 'Body Care',
          'subtitle' : 'Body Lotion, Body Scrub, Body Oil'
        },
        {
          'title' : 'Hair Care',
          'subtitle' : 'Shampoo, Conditioner, Hair Treatments'
        },
        {
          'title' : 'Cosmetics',
          'subtitle' : 'Makeup, Skincare-Makeup Hybrid Products'
        },
        {
          'title' : 'Scents',
          'subtitle' : 'Perfume, Essential Oil, Diffuser, Scented Candle, Linen Spray, Room Spray'
        },
        {
          'title' : 'Health & Wellbeing',
          'subtitle' : 'Fitness Centers, Health and Wellness Clubs, Meditation Centers, Beauty/hair/skin clinics'
        }
      ]
    },

    {
      'title' : 'Fashion',
      'subtitle' : 'Pilihan pakaian dan aksesori yang modis dengan gaya terkini hingga desain klasik yang cocok untuk berbagai kesempatan, serta teknologi produksi fashion.',
      'items' : [
        {
          'title' : 'Apparel',
          'subtitle' : 'Everyday Wear, Formal Wear, Activewear, Underwear'
        },
        {
          'title' : 'Accessories',
          'subtitle' : 'Jewelry, Bags, Footwear, Hats, Belts, Scarves'
        },
        {
          'title' : 'Footwear',
          'subtitle' : 'Casual Shoes, Formal Shoes, Sports Shoes'
        },
        {
          'title' : 'Fashion Manufacturing',
          'subtitle' : 'Textile Production, Raw Material Sourcing, Bespoke Tailoring'
        },
       
      ]
    },

    {
      'title' : ' Innovative Food & Beverages',
      'subtitle' : 'Produk makanan dan minuman yang unik dan kreatif, menyajikan rasa dan pengalaman baru yang menggugah selera dan memanjakan lidah.',
      'items' : [
        {
          'title' : 'Innovative Foods',
          'subtitle' : 'New Food Concepts, Artisanal Products, Healthy Foods'
        },
        {
          'title' : 'Beverages',
          'subtitle' : 'Unique Drinks, Craft Beverages, Functional Drinks (e.g. health-boosting drinks)'
        },
        {
          'title' : 'Snacks',
          'subtitle' : 'Unique or Innovative Snack Products'
        },
       
       
      ]
    },

    {
      'title' : 'Tech & Tech-Enabled Startups',
      'subtitle' : 'Inovasi teknologi yang mengubah cara kita bekerja dan hidup, berfokus pada solusi pintar dan alat digital yang memudahkan kehidupan sehari-hari.',
      'items' : [
        {
          'title' : 'Agritech',
          'subtitle' : 'Agriculture, Farming, Aquatech Innovations'
        },
        {
          'title' : 'Edutech',
          'subtitle' : 'Educational Tools, Learning Platforms'
        },
        {
          'title' : 'Logistic-Tech',
          'subtitle' : 'Supply Chain and Delivery Innovations, Inventory Management'
        },

        {
          'title' : 'Enterprise Software and Solutions',
          'subtitle' : 'Business Management Tools, Productivity Software'
        },

        {
          'title' : 'Healthtech',
          'subtitle' : 'Healthcare Innovations, Telemedicine Solutions'
        },

        {
          'title' : 'Smart City',
          'subtitle' : 'Urban Solutions, Smart Infrastructure'
        },

        {
          'title' : 'Climate-Tech',
          'subtitle' : 'Environmental Solutions, Sustainability Technologies'
        },
        {
          'title' : 'Financial Services',
          'subtitle' : 'Fintech Innovations, Digital Payment Solutions'
        },
       
       
      ]
    },
   
   
  ]


  faq = [
    
    {
      'title' : 'Apa saja kriteria untuk calon peserta Wirausaha Muda Mandiri?',
      'items' : [
        // {
        //   'subtitle' : '- Pengusaha yang yang merupakan warga negara Indonesia berumur 18 – 35 tahun'
        // },
        {
          'subtitle' : '- Omset per tahun minimal Rp 500 juta, atau bisnis sudah berjalan minimal 2 tahun untuk kategori Fashion dan Food & Beverages, Beauty & Wellness, dan 1 tahun untuk Tech & Tech-Enabled Startups'
        },
        {
          'subtitle' : '- Usaha/inovasi tidak melanggar hukum'
        },
        {
          'subtitle' : '- Peserta merupakan Pemilik/Co-Founder/CEO/C-Level dari bisnis yang didaftarkan - 1 orang per perusahaan'
        },
        {
          'subtitle' : '- Bisnis berbasis di Indonesia dan sudah memiliki legalitas bisnis (minimal berupa NIB atau HAKI)'
        },
        {
          'subtitle' : '- Memiliki rekening Bank Mandiri. Dapat berupa rekening pribadi maupun rekening bisnis. Apabila belum mempunyai silahkan melakukan proses pembuatan rekening dengan mudah ke tautan berikut. <a target="_blank" href="https://bankmandiri.co.id/livin/edukasi/cara-buka-rekening">Pembuatan rekening Bank mandiri</a> '
        },
        
      ]
    },

    
    {
      'title' : 'Apakah saya masih layak untuk mendaftar ke Wirausaha Muda Mandiri jika saya telah mendapatkan pendanaan untuk perusahaan saya dan sebelumnya telah berpartisipasi dalam program akselerator lain?',
      'subtitle' : 'Tentu saja! Tujuan kami adalah membantu mengembangkan bisnis Anda untuk mencapai skala yang lebih besar. Selama Anda dan bisnis Anda sesuai dengan persyaratan program, Anda dipersilakan untuk mendaftar.',
      
    },
    {
      'title' : 'Apakah founder yang didaftarkan boleh lebih dari satu?',
      'subtitle' : 'Tidak, program ini hanya dapat menerima satu Pemilik/Co-Founder/CEO/C-Level per perusahaan.',
      
    },
    {
      'title' : 'Apakah saya perlu mengirimkan pitch deck?',
      'subtitle' : 'Ya, Anda akan diminta untuk mengirimkan pitch deck terbaru Anda pada formulir pendaftaran.',
      
    },
    {
      'title' : 'Bagaimana format pitch deck yang harus saya persiapkan?',
      'subtitle' : 'Berikut rekomendasi template pitch deck dari Wirausaha Muda Mandiri (WMM) 2024: Pitch Deck Template for WMM. Anda dapat menggunakan desain kami atau membuat desain pitch deck Anda sendiri. <a target="_blank" href="https://docs.google.com/presentation/d/18z_v2QFw6B9c6DvghSGmV6021KMypEVO2oYnMeyLCio/edit#slide=id.p">Contoh Pitchdeck</a> ',
      
    },
    {
      'title' : 'Berapa lama program ini akan berlangsung?',
      'subtitle' : 'Program WMM ini akan dimulai dari Oktober hingga Januari, dengan rincian:',
      'items' : [
        {
          'subtitle' : '- Oktober - Desember 2024: Proses seleksi 20 peserta Wirausaha Muda Mandiri'
        },
        {
          'subtitle' : '- Januari 2025: Kompetisi Wirausaha Muda Mandiri (WMM)'
        }
      ]
    },
    {
      'title' : 'Bagaimana tahapan seleksi WMM 2024?',
      'subtitle' : null,
      'items' : [
        {
          'subtitle' : '- Pemilik/Co-Founder/CEO/C-Level perusahaan mengisi form dan melengkapi syarat pendaftaran untuk seleksi administrasi'
        },
        {
          'subtitle' : '- Top 40 Perusahaan yang lolos seleksi administrasi akan dihubungi melalui email dan atau whatsapp untuk melanjutkan ke tahap seleksi lanjutan dan mendapatkan pembekalan materi'
        },
        {
          'subtitle' : '- Top 20 Perusahaan yang lolos seleksi akan lanjut ke tahap kompetisi WMM 2024'
        }
      ]
    },
    {
      'title' : 'Apakah kegiatan dilakukan secara offline?',
      'subtitle' : 'Betul, Kompetisi WMM 2024 diadakan secara offline selama kurang lebih 1 minggu di bulan Januari 2025. Kami akan mengumumkan detail kegiatan kepada 20 peserta terpilih.',
      
    },
    {
      'title' : 'Bagaimana cara mengetahui status registrasi saya?',
      'subtitle' : 'Semua pendaftar yang terpilih sebagai 40 besar akan diberitahukan melalui email dan/atau Whatsapp, serta akan diumumkan di website dan media sosial resmi Wirausaha Muda Mandiri. Pendaftar yang terpilih akan diumumkan paling lambat di minggu pertama November 2024.',
      
    },
    {
      'title' : 'Saya sudah mendaftar program WMM tahun lalu atau tahun sebelumnya, apakah masih bisa mendaftar untuk tahun ini?',
      'subtitle' : 'Boleh, apabila di tahun-tahun sebelumnya Anda belum terpilih menjadi Top 40 atau apabila Anda memiliki bisnis dengan merek baru yang lebih berkembang.',
    
    },
    {
      'title' : 'Saya belum punya nomor rekening Bank Mandiri, apakah boleh mendaftar program?',
      'subtitle' : 'Semua pendaftar program WMM wajib memiliki rekening Mandiri. Jangan khawatir apabila Anda belum memiliki rekening Mandiri, karena Anda dapat membuat rekening mandiri secara mudah melalui aplikasi Livin’ by Mandiri di tautan berikut: <a target="_blank" href="https://bankmandiri.co.id/livin/edukasi/cara-buka-rekening">Cara Daftar dan Buka Rekening Bank Mandiri.</a>'
,
    
    },
    {
      'title' : 'Berapa biaya pendaftaran untuk mengikuti program ini?',
      'subtitle' : 'Program ini tidak dipungut biaya. Komitmen yang kami minta dari Anda adalah waktu dan kehadiran penuh selama program berlangsung.'
    
    },
    {
      'title' : 'Apakah jumlah peserta tidak terbatas?',
      'subtitle' : 'Kami akan memilih 20 peserta untuk mengikuti Kompetisi Wirausaha Muda Mandiri. Jumlah peserta kami buat terbatas supaya lebih kondusif, serta diskusi dan pembelajaran dalam program ini berjalan lebih maksimal.'
    
    },
    {
      'title' : 'Kapan batas waktu untuk melakukan registrasi?',
      'subtitle' : 'Kami akan menutup registrasi 2 minggu setelah registrasi dibuka. Namun, kami dapat menutup registrasi lebih cepat apabila jumlah pendaftar sudah melebihi kuota. Oleh karena itu, kami sangat menyarankan untuk segera melakukan pendaftaran apabila berminat untuk mengikuti program WMM. '
    
    },
    {
      'title' : 'Seperti apa detail aktivitas dalam Kompetisi WMM 2024?',
      'subtitle' : 'Detail aktivitas akan diinfokan lebih lanjut kepada TOP 20 WMM 2024'
    
    },
    {
      'title' : 'Apakah ada kanal komunikasi bagi peserta saat WMM 2024?',
      'subtitle' : 'Selama proses berlangsung, seluruh peserta dapat menghubungi Mandiri Call 14000 atau wirausahamandiri.id@gmail.com'
    
    },
   
  ]

  activity = [
    {
      'title' : 'Sesi Mentoring',
      'subtitle' : 'Kesempatan dimentori langsung oleh pemilik bisnis sukses lewat kegiatan FGD dan workshop',
      'icon' : '/assets/logo/mentoring.svg'
    },
    {
      'title' : 'Company Visit',
      'subtitle' : 'Kunjungan langsung ke perusahaan dan bisa langsung berdikusi dengan C-Level dan founder "',
      'icon' : '/assets/logo/company.svg'
    },
    {
      'title' : 'Workshop',
      'subtitle' : 'Belajar tentang pemasaran, storytelling, operasional, dan keuangan serta praktek langsung',
      'icon' : '/assets/logo/workshop.svg'
    },
    {
      'title' : 'Speed Dating',
      'subtitle' : 'Kenalkan ide kamu ke investor dan dapatkan peluang investasi dengan bangun kemitraan di Speed Dating.',
      'icon' : '/assets/logo/speed.svg'
    },
 
    {
      'title' : 'Networking',
      'subtitle' : 'Kesempatan berjejaring dengan founder, mentor, dan speaker ternama untuk dukungan dan info bisnis.',
      'icon' : '/assets/logo/networking.svg'
    },
  ]


  
  show_sidebar: boolean;
  active_program_mobile: string;
  constructor() { }

  ngOnInit(): void {
    this.active_program = 'Aktivitas'
     this.active_program_mobile = 'Kriteria Peserta'
    this.show_sidebar = false
  }

  selectProgram(item){
     this.active_program = item.title
     this.active_program_mobile = item.title
     
     if(this.active_program == 'Aktivitas'){
      this.show_sidebar = false
     }else{
      this.show_sidebar = true
     }
  }

  selectBusiness(item){
    if(item.is_active){
      item.is_active = false
    }else{
      item.is_active = true
    }
    
  }

}
